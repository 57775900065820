<template>
  <div class="aboutus">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>关于我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="text">
      <span>简介</span>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入公司简介，不超过100字"
        v-model="textarea"
      >
      </el-input>
    </div>
    <div class="logo">
      <span>LOGO</span>
      <input
        type="file"
        name=""
        id=""
        hidden
        ref="file"
        @change="onFileChange"
      />
      <el-button type="primary" @click="onSendImg">上传图片</el-button>
      <el-image style="width: 100px; height: 100px" :src="url"></el-image>
    </div>
    <div class="btn">
      <el-button type="primary">保存</el-button>
      <el-button type="primary">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {
      textarea: "",
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
    };
  },
  methods: {
    onSendImg() {
      this.$refs.file.click();
    },
    onFileChange() {
      this.url = window.URL.createObjectURL(this.$refs.file.files[0]);
      this.$refs.file.value = "";
    },
  },
};
</script>
<style lang="less" scoped>
.aboutus {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid #ccc;
  }
  .text {
    display: flex;
    align-items: center;
    margin-top: 50px;
    /deep/.el-textarea {
      width: 500px !important;
      margin: 0 50px;
      textarea {
        width: 100%;
        height: 120px;
      }
    }
  }
  .logo {
    margin-top: 50px;
    span {
      margin-right: 50px;
    }
    .el-image {
      display: block;
      margin-top: 20px;
      margin-left: 80px;
    }
  }
  .btn {
    .el-button {
      margin: 50px 20px;
    }
  }
}
</style>